import React from 'react'
import FooterFour from '../../common/footer/FooterFour'
import HeaderOne from '../../common/header/HeaderOne'
import SEO from '../../common/SEO'

function RefundAndCancellation() {
    return (
        <>
            <SEO title="Blog List || Doob - React Business  Template" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}

                <div className="container rn-section-gapTop">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner pt--80 text-center">
                                <h1 className="title display-one">Refund & Cancellations</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Slider Area  */}

                <div className="container rn-section-gapTop">
                    <p>V-Art Services provides an extensive portfolio for you to view before hiring us for your project so you can see the quality level of our work and are completely comfortable working with us. If you have any questions or reservations, please contact us prior to making a payment for our services. All changes to orders or projects, refund requests or cancellations must be received in writing by mail, email or fax. Thank you!</p>
                    <h3>Web & Other Virtual Services</h3>
                    <p>Payments for custom web, graphic design and other services and projects are made to V-Art Services in increments as a courtesy to the client. Once a payment or deposit is made, it is non-refundable. If a project is canceled or postponed, all monies paid are retained by V-Art Services and if applicable, a fee for all work completed beyond what was already paid for shall be paid by the client. The development process is such: once client approval is rendered, V-Art Services shall commence on that stage of the project. Refund may be considered if the programmer / designer / content writer / SEO expert (or any other resource / staff) has not started the project. V-Art Services provides an extensive portfolio for you to view before hiring us for your project so you can see the quality level of our work and are completely comfortable working with us. If you have any questions or reservations, please contact us prior to making a payment for our services. All changes to orders or projects, refund requests or cancellations must be received in writing by mail, email or fax. Thank you!</p>
                    <h3>Chargebacks</h3>
                    <p>If V-Art Services receives a chargeback or payment dispute from a credit card company or bank, your service and/or project will be suspended without notice. A $25 chargeback fee (issued to recover fees passed on to us by the credit company), plus any outstanding balances accrued as a result of the chargeback(s) must be paid in full before service is restored, files delivered, or any further work is done. Instead of issuing a chargeback, contact us to address any billing issues. Requesting a chargeback for a valid charge from us is fraud, and is never an appropriate or legal means of obtaining a refund. Please read and make sure you fully understand our refund policy prior to making a payment.</p>
                </div>
                <FooterFour />
            </main>
        </>
    )
}

export default RefundAndCancellation